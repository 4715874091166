import React from "react";
import { NavLink } from 'react-router-dom'


import {
  Box,
  FooterContainer,
  Row,
  Column,
  FooterLink,
  Heading,
} from "./FooterStyles";

const Footer = () => {
  return (
      <Box>
          <h1
              style={{
                  color: "green",
                  textAlign: "center",
                  marginTop: "10px",
              }}
          >
              
          </h1>
          <FooterContainer>
              <Row>
                     
                  <Column>
                      <Heading>Our Company</Heading>
                      <FooterLink href="https://printsundar.com/#/contact">
                          Contact Us
                      </FooterLink>
                     
                      </Column>
                  <Column>
                    <Heading>Our Policies</Heading>
                      <FooterLink href="https://printsundar.com/#/TermsAndCondition">
                      Terms and Conditions
                      </FooterLink>
                      <FooterLink href="https://printsundar.com/#/PrivacyPolicy">
                      Privacy and Cookie Policy
                      </FooterLink>
                      <FooterLink href="https://printsundar.com/#/RefundPolicy">
                      Refund Policy
                      </FooterLink>
                      </Column>
                      <Column>
                    <Heading>Our Policies Cont..</Heading>
                      <FooterLink href="https://printsundar.com/#/ShippingPolicy">
                      Shipping policy
                      </FooterLink>
                      <FooterLink href="https://printsundar.com/#/CancellationPolicy">
                      Cancellation Policy
                      </FooterLink>
                      </Column>
              </Row>
          </FooterContainer>
      </Box>
  );
};
export default Footer;